//
// Shortcut
//

.shortcuts {
}

.shortcut-media {
  @include transition($transition-cubic-bezier);
}

.shortcut-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;

  small {
    color: black;
    display: block;
    margin-top: 0.75rem;
    font-size: $h5-font-size;
    font-weight: $heading-font-weight;
  }

  &:hover {
    .shortcut-media {
      transform: scale(1.1);
    }
  }
}
