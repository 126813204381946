/* You can add global styles to this file, and also import other style files */
//@import url('https://fonts.cdnfonts.com/css/tt-norms-pro');

.tableFixHead          { overflow: auto; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

.control-error-message {
    font-size: 0.8rem;
    margin-top: 1rem;
 
}

::-moz-selection { /* Code for Firefox */
    color: var(--light);
    background: var(--primary);
  }
  
  ::selection {
    color:var(--light);
    background: var(--primary);
  }


.list-unstyled-custom {
    list-style: none;
}

.line-break {
  word-wrap: break-word;
  white-space: pre-wrap !important;
  word-break: break-word;
}

.link-custom {
  text-decoration: underline !important;
  color: var(--pi-blue-fluo) !important;
}

.font-size-large {
  font-size: large;
}

a {
  cursor: pointer;
}

.nopointer {
  cursor:default !important;
}

.pointer {
  cursor:pointer;
}

.border-dashed {
  border-top: 1px dashed;
  border-color: var(--primary);
}

.control-error-message {
  position: absolute !important;
  left: 0 !important;
  bottom: -21px !important;
}

.toast-container .ngx-toastr {
  //box-shadow: 0 0 12px #3b3b3b !important;
  width: 500px !important;
  text-align: center !important;
}

.gradient-bg {
  background: linear-gradient(to right, var(--secondary), var(--primary));
}

.gradient-round {
  border-radius: 25px;
  padding: 2px;
  width: 100%;
}

.ngx-file-drop-style {
  display: block;
  height: 100%;
}

.modal-xxl {
  max-width: 1200px !important;
  width: 1200px;
}

.pirog-pro-modal {
  max-width: 800px !important;
  width: 800px;
}

.template-request-link:hover {
  border: 3px dashed white !important; 
  cursor:pointer;
}

.primary-template-request-link:hover {
  border: 3px dashed var(--primary) !important; 
  cursor:pointer;
}
// ******************
// SEO
// ******************
.seo_ratio_1 {
  aspect-ratio: 1;
}

.seo_ratio_10_6 {
  aspect-ratio: 10 / 6;
}

.seo_ratio_750_525 {
  aspect-ratio: 750 / 525;
}

.seo_ratio_10_7 {
  aspect-ratio: 10 / 7;
}

.seo_ratio_10_6 {
  aspect-ratio: 10 / 6;
}

.seo_ratio_2_1 {
  aspect-ratio: 2 / 1;
}

.seo_ratio_3_1 {
  aspect-ratio: 3 / 1;
}

.seo_ratio_3_2 {
  aspect-ratio: 3 / 2;
}

.seo_ratio_opengraph {
  aspect-ratio: 1200 / 630;
}
.seo_ratio_16_9{
  aspect-ratio: 16 / 9;
}

// ******************
// Z_INDEX 
// carrousel & cie
// ******************
.z_index_back {
  z-index: 1 !important;
}
.z_index_medium {
  z-index: 2 !important;
}
.z_index_top {
  z-index: 3 !important;
}
// ******************
// TOASTER
// ******************

.toast-success {
  background-color: var(--success) !important;
}

.toast-error {
  background-color: var(--danger) !important;
}

.toast-warning {
  background-color: var(--warning) !important;
}

.toast-info {
  background-color: var(--primary) !important;
}

.vertical-hr {
  width: 1px; 
  height: 20px; 
  background-color: #3a3535; 
  margin: 0 auto;
}

.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
}

.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

.console-navbar {
  min-height: 4rem;
}

.cc-window, .cc-window a {
  line-height: 1.2 !important;
}